<template>
  <div class="lad-navigation--logo">
    <nuxt-link
      to="/"
      :prefetch="false"
      title="LocalAdventures"
      @click="handleClick"
    >
      <img
        v-if="logo === 'FlairAirlines'"
        src="@/assets/images/logo/flair-airlines.png"
        class="sprite-icons"
      />
      <svg-icon v-else name="static-pages/logo-local" class="sprite-icons" />
    </nuxt-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GenericEventMixin from '@/utils/mixin/generic-event'

export default {
  name: 'LadNavigationLogo',
  mixins: [GenericEventMixin],
  computed: {
    ...mapState('experience', {
      exExperience: (state) => state.experience,
      totalAmount: (state) => state.sales_plan.total_amount,
    }),
    ...mapState('settings', ['logo', 'currency']),
  },
  methods: {
    async handleClick() {
      if (this.$route.name.startsWith('landing-permalink')) {
        const data = {
          name: 'go_homepage',
          currency: this.setting.currency,
          experience: {
            ...this.exExperience,
            total_amount: this.totalAmount,
          },
        }

        await this.sendGenericEvent(data)
      }
    },
  },
}
</script>

<style lang="scss">
.lad-navigation--logo {
  a {
    float: left;
  }

  img {
    &.sprite-icons {
      object-fit: contain;
    }
  }
}
</style>
