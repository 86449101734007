export default {
  methods: {
    async sendGenericEvent(data) {
      /** Sending event to Facebook */
      if (typeof this.$fb !== 'undefined') {
        await this.$fb.track(data.fb_name || data.name, {
          value: data.experience.total_amount,
          content_name: data.experience.name,
          currency: data.currency,
        })
      }

      /** Sending event to Google Analytics */
      if (typeof this.$gtag !== 'undefined') {
        await this.$gtag('event', data.ga_name || data.name, {
          value: data.experience.total_amount,
          event_label: data.experience.name,
          currency: data.currency,
          page_title: this.$metaInfo.title,
          page_location: window.location.href,
        })
      }

      /** Sending event to TikTok */
      if (typeof this.$tiktok !== 'undefined') {
        await this.$tiktok.track(data.name, {
          value: data.experience.total_amount,
          currency: data.currency,
          contents: [
            {
              content_id: data.experience.uuid,
              content_type: 'experience',
              content_name: data.experience.name,
            },
          ],
        })
      }
    },
  },
}
